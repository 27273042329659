import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../helper/helper.service";
var ApiService = /** @class */ (function () {
    function ApiService(http, helper) {
        this.http = http;
        this.helper = helper;
        this.apiUrl = environment.apiUrl;
    }
    ApiService.prototype.getOutTx = function (address, slice) {
        return this.http.get(this.apiUrl + '/addresses/' + address + '/outtxs/' + slice);
    };
    ApiService.prototype.getInTx = function (address, slice) {
        return this.http.get(this.apiUrl + '/addresses/' + address + '/intxs/' + slice);
    };
    // fetch all block list
    ApiService.prototype.fetch_latest_blocks_api = function (slice) {
        return this.http.get(this.apiUrl + '/blocks?slice=' + slice);
    };
    // fetch all transaction list
    ApiService.prototype.fetch_transaction_api = function (slice) {
        return this.http.get(this.apiUrl + '/transactions?slice=' + slice);
    };
    // fetch one block details
    ApiService.prototype.fetch_block_details = function (slice) {
        return this.http.get(this.apiUrl + '/blocks/' + slice);
    };
    // fetch one transaction details
    ApiService.prototype.fetch_transaction_details = function (slice) {
        return this.http.get(this.apiUrl + '/transactions/' + slice);
    };
    // fetch one address details
    ApiService.prototype.fetch_address_details = function (slice) {
        return this.http.get(this.apiUrl + '/addresses/' + slice);
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.Http), i0.ɵɵinject(i2.HelperService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
