import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import Web3 from 'web3';
import HdKeyring from 'eth-hd-keyring';
import Mnemonic from 'bitcore-mnemonic';
import FileSaver from 'file-saver';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
var HelperService = /** @class */ (function () {
    function HelperService(router) {
        this.router = router;
        this.accountList = [];
        this.accObeservable = {
            accounts: [],
            balances: {}
        };
        this.accountListBalance = {};
        this.accountLoaded = false;
        this.currentAccount = {
            balance: 0,
            symbol: 'NIT'
        };
        this.walletInfo = {
            total: 1,
            selected: 0
        };
        this.globalPause = false;
        this.transactionList = [];
        this.map = new Map();
        this.algorithm = 'aes-256-cbc';
        this.currentBlock = null;
        this.currentHash = null;
        this.currentAddress = null;
        this.newAcc = new EventEmitter();
        this.selAcc = new EventEmitter();
        if (!this.globalPassword) {
            // router.navigate(['login']);
        }
        this.web3 = new Web3(null);
        // this.web3.setProvider('http://18.***.***.0:8545');
        this.web3.eth.defaultGasPrice = 0;
    }
    HelperService.prototype.checkBalance = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.web3.eth.getBalance(this.accountList[this.slectedAddress]).then(function (x) {
                            _this.currentAccount.balance = _this.web3.utils.fromWei(x);
                            console.log(_this.currentAccount.balance);
                            return _this.currentAccount.balance;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HelperService.prototype.getBalance = function (acc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.web3.eth.getBalance(acc).then(function (x) {
                            return Promise.resolve(_this.web3.utils.fromWei(x));
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HelperService.prototype.getAddress = function () {
        this.currencAddress = this.accountList[this.slectedAddress];
        return this.currencAddress;
    };
    HelperService.prototype.save = function () {
        this.web3.eth.accounts.wallet.save(this.globalPassword, 'nit_wallet');
    };
    HelperService.prototype.encrypt = function (text, passPhrase) {
        var encrypted = CryptoJS.AES.encrypt(text, passPhrase);
        window.localStorage.setItem('nesten_hd', encrypted);
        window.localStorage.setItem('wallet_info', JSON.stringify(this.walletInfo));
    };
    HelperService.prototype.decrypt = function (passPhrase) {
        try {
            var cyphered = window.localStorage.getItem('nesten_hd');
            var bytes = CryptoJS.AES.decrypt(cyphered.toString(), passPhrase);
            var decrypted = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted : ", decrypted);
            return decrypted;
        }
        catch (error) {
            // alert("Wrong Pin")
            console.log("Error: " + error);
        }
    };
    HelperService.prototype.saveAsFile = function (words) {
        var blob = new Blob([words], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "nestenwallet.txt");
    };
    HelperService.prototype.generateSeed = function () {
        var words = new Mnemonic(Mnemonic.Words.ENGLISH).toString();
        console.log("WORDS", words);
        return words;
    };
    HelperService.prototype.initHdWallet = function (mnemonic, walletobj) {
        this.hdkeyring = new HdKeyring({
            mnemonic: mnemonic,
            numberOfAccounts: walletobj.total,
        });
    };
    HelperService.prototype.seeMap = function () {
        console.log("Map:- ", this.map);
        var keys = Array.from(this.map.keys());
        var values = Array.from(this.map.values());
        var list = [
            keys, values
        ];
        console.log(keys, values);
        return (list);
    };
    HelperService.prototype.removeWallet = function () {
        window.localStorage.removeItem('nesten_hd');
        window.location.reload();
    };
    HelperService.prototype.loadWalletWithSeedWord = function (passPhrase) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mnemonic, walletObj, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.decrypt(passPhrase)];
                    case 1:
                        mnemonic = _b.sent();
                        if (!!mnemonic) return [3 /*break*/, 2];
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: 'Wrong Key'
                        });
                        return [2 /*return*/, false];
                    case 2:
                        walletObj = JSON.parse(window.localStorage.getItem('wallet_info'));
                        return [4 /*yield*/, this.initHdWallet(mnemonic, walletObj)];
                    case 3:
                        _b.sent();
                        // Till this ok
                        _a = this;
                        return [4 /*yield*/, this.hdkeyring.getAccounts()];
                    case 4:
                        // Till this ok
                        _a.accountList = _b.sent(); // All accounts is in the list
                        console.log("LIST ", this.accountList);
                        console.log("accountBalList ", this.accountListBalance);
                        return [4 /*yield*/, this.balanceFiller()];
                    case 5:
                        _b.sent();
                        this.slectedAddress = walletObj.selected; // By default 0 is selected
                        this.router.navigate(['dashboard']);
                        return [2 /*return*/, true];
                }
            });
        });
    };
    HelperService.prototype.balanceFiller = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rtrn;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountList.map(function (address) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.web3.eth.getBalance(address).then(function (blnc) {
                                            _this.accountListBalance[address] = _this.web3.utils.fromWei(blnc);
                                            return address;
                                        })];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); })];
                    case 1:
                        rtrn = _a.sent();
                        Promise.all(rtrn).then(function (x) {
                            _this.newAcc.emit(null);
                            console.log(x);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    HelperService.prototype.createAddress = function (passPhrase) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mnemonic, walletObj, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log("helper create address visited");
                        return [4 /*yield*/, this.decrypt(passPhrase)];
                    case 1:
                        mnemonic = _b.sent();
                        if (!mnemonic) return [3 /*break*/, 5];
                        walletObj = JSON.parse(window.localStorage.getItem('wallet_info'));
                        walletObj.total = Number(walletObj.total) + 1;
                        console.log(walletObj.total);
                        window.localStorage.setItem('wallet_info', JSON.stringify(walletObj));
                        return [4 /*yield*/, this.initHdWallet(mnemonic, walletObj)];
                    case 2:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.hdkeyring.getAccounts()];
                    case 3:
                        _a.accountList = _b.sent(); // All accounts is in the list
                        console.log("LIST ", this.accountList);
                        this.slectedAddress = walletObj.selected; // By default 0 is selected
                        return [4 /*yield*/, this.balanceFiller()];
                    case 4:
                        _b.sent();
                        // this.router.navigate(['dashboard']);
                        return [2 /*return*/, true];
                    case 5:
                        setTimeout(function () {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: 'Wrong Key'
                            });
                        }, 500);
                        console.log("EROROR");
                        return [2 /*return*/, false];
                }
            });
        });
    };
    HelperService.prototype.selectAddress = function (addressPosition) {
        var walletObj = JSON.parse(window.localStorage.getItem('wallet_info'));
        if (addressPosition <= walletObj.total) {
            walletObj.selected = addressPosition;
            window.localStorage.setItem('wallet_info', JSON.stringify(walletObj));
            this.slectedAddress = addressPosition;
            console.log("New Default address : ", this.accountList[this.slectedAddress]);
        }
        this.selAcc.emit(null);
    };
    HelperService.prototype.sendNit = function (to, value, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var privateKey, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3.utils.isAddress(to)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.globalPause = true;
                        return [4 /*yield*/, this.hdkeyring.exportAccount(this.accountList[this.slectedAddress])];
                    case 2:
                        privateKey = _a.sent();
                        this.web3.eth.accounts.signTransaction({
                            to: to,
                            value: this.web3.utils.toWei(value),
                            gas: 2000000,
                            gasPrice: 0
                        }, '0x' + privateKey).then(function (tx) {
                            _this.web3.eth.sendSignedTransaction(tx.rawTransaction)
                                .on('transactionHash', function (hash) {
                                console.log("hash", hash);
                            }).on('receipt', function (receipt) {
                                _this.receipt = receipt;
                                _this.globalPause = false;
                                console.log("receipt", receipt);
                                cb(true, "Transaction Successfull");
                            }).catch(function (x) {
                                console.log("ERROR :", x);
                                _this.globalPause = false;
                                cb(false, x);
                            });
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log("error", error_1);
                        cb(false, "Transaction failed");
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        cb(false, "Please enter valid address");
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    HelperService.prototype.setBlockNumber = function (blockNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.currentBlock = blockNumber;
                return [2 /*return*/];
            });
        });
    };
    HelperService.prototype.setTransactionHash = function (hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.currentHash = hash;
                return [2 /*return*/];
            });
        });
    };
    HelperService.prototype.setAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.currentAddress = address;
                return [2 /*return*/];
            });
        });
    };
    return HelperService;
}());
export { HelperService };
