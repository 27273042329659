import * as i0 from "@angular/core";
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.prototype.setUser = function (user) {
        localStorage.setItem("nestenUserAuth", JSON.stringify(user));
    };
    AuthService.prototype.user = function () {
        return JSON.parse(localStorage.getItem("nestenUserAuth"));
    };
    AuthService.prototype.removeUser = function () {
        localStorage.removeItem("nestenUserAuth");
    };
    AuthService.prototype.isGuest = function () {
        return !this.user();
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
